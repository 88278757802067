import "./_ModalWrapper.scss";
import ReactDOM from "react-dom";
import { useContext } from "react";
import { ModalContext } from "../../store/ModalContext";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../assets/svg/close-icon.svg";

const Backdrop = ({ onClickBackdrop }) => {
  return <div id="modal-backdrop" onClick={onClickBackdrop}></div>;
};

const ModalOverlay = ({ videoUrl, onCloseModal }) => {
  const { t } = useTranslation();

  return (
    <div id="modal-wrapper">
      <img
        className="close-icon"
        src={CloseIcon}
        role="button"
        aria-label="Close"
        alt={t("MODAL.CLOSE_BUTTON")}
        onClick={onCloseModal}
      />
      <video controls autoPlay>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const ModalWrapper = ({ videoUrl }) => {
  const modalCtx = useContext(ModalContext);
  return (
    <>
      {modalCtx.isOpen &&
        ReactDOM.createPortal(
          <Backdrop onClickBackdrop={modalCtx.closeModal} />,
          document.getElementById("backdrop-root")
        )}
      {modalCtx.isOpen &&
        ReactDOM.createPortal(
          <ModalOverlay
            videoUrl={videoUrl}
            onCloseModal={modalCtx.closeModal}
          />,
          document.getElementById("modal-root")
        )}
    </>
  );
};

export default ModalWrapper;
