import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./locales/en/en.json"),
    },
    de: {
      translations: require("./locales/de/de.json"),
    },
    es: {
      translations: require("./locales/es/es.json"),
    },
    it: {
      translations: require("./locales/it/it.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "es", "de", "it"];

export default i18n;
