import { useState } from "react";
import { useTranslation } from "react-i18next";
import ShowMoreButton from "../../UI/ShowMoreButton";
import EducationSection from "./EducationSection/EducationSection";
import PortfolioSection from "./PortFolioSection/PortfolioSection";
import WorkSection from "./WorkSection/WorkSection";
import en from "../../../i18n/locales/en/en.json";
import { CSSTransition } from "react-transition-group";

import "./_ProfessionalInfoContainer.scss";

const ProfessionalInfoContainer = ({ sectionKey, sectionData, groupKey }) => {
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const toggleShowMoreButton = () => {
    setShowMore((prevStat) => !prevStat);
    setIsRendered(true);
  };

  const sectionContent = Object.keys(sectionData).map((sectionItem) => {
    switch (sectionKey) {
      case "EDUCATION":
        return (
          <EducationSection
            key={sectionItem}
            sectionData={en[groupKey][sectionKey].VALUES}
            groupKey={sectionKey}
            itemKey={sectionItem}
          />
        );
      case "WORK":
        return (
          <WorkSection
            key={sectionItem}
            sectionData={en[groupKey][sectionKey].VALUES}
            groupKey={sectionKey}
            itemKey={sectionItem}
          />
        );
      case "WEB_DEV_PORTFOLIO":
      case "ALGORITHMS_PORTFOLIO":
        return (
          <PortfolioSection
            key={sectionItem}
            sectionData={en[groupKey][sectionKey].VALUES}
            groupKey={sectionKey}
            itemKey={sectionItem}
          />
        );
      default:
        return <></>;
    }
  });

  return (
    <section
      className={`cv-section ${sectionKey.toLowerCase()}-section animate__animated animate__fadeInUp`}
      key={sectionKey}
    >
      <h2>{t(`${groupKey}.${sectionKey}.TITLE`)}</h2>
      <div className="terminal-container">
        {sectionContent.slice(0, 2)}
        {isRendered && (
          <CSSTransition
            in={showMore}
            timeout={0}
            classNames="dropdown-container"
            appear
            unmountOnExit
          >
            <div>{sectionContent.slice(2)}</div>
          </CSSTransition>
        )}
        {sectionContent.length > 2 && (
          <div
            onClick={toggleShowMoreButton}
            className="show-more-container"
            timeout={450}
          >
            <ShowMoreButton isMore={!showMore} />
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfessionalInfoContainer;
