import { useTranslation } from "react-i18next";
import "./_PersonalInfoSection.scss";

const PersonalInfoSection = ({ groupKey, data }) => {
  const { t } = useTranslation();

  const createItemToShow = (keys, groupKey, sectionKey) => {
    return (
      <p>
        {keys.length > 1 && <span>[</span>}

        {`${keys
          .map((key) => {
            return `'${t(
              `${groupKey.toUpperCase()}.VALUES.${sectionKey}.VALUES.${key}`
            )}'`;
          })
          .join(", ")}`}
        {keys.length > 1 && <span>]</span>}
      </p>
    );
  };

  return (
    <section
      className={`cv-section ${groupKey}-section animate__animated ${
        groupKey === "skills" ? "animate__fadeInDown" : "animate__fadeInRight"
      }`}
    >
      <h2>{t(`${groupKey.toUpperCase()}.TITLE`)}</h2>
      <div className="object-container">
        {Object.keys(data[groupKey.toUpperCase()]["VALUES"]).map(
          (sectionKey) => {
            return (
              <div key={sectionKey}>
                <h3>
                  {t(`${groupKey.toUpperCase()}.VALUES.${sectionKey}.TITLE`)}:{" "}
                </h3>
                {createItemToShow(
                  Object.keys(
                    data[groupKey.toUpperCase()]["VALUES"][sectionKey].VALUES
                  ),
                  groupKey,
                  sectionKey
                )}
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default PersonalInfoSection;
