import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ModalContext } from "../../../../store/ModalContext";
import ModalWrapper from "../../../UI/ModalWrapper";
import "./_PortfolioSection.scss";

const PortfolioSection = ({ sectionData, groupKey, itemKey }) => {
  const modalCtx = useContext(ModalContext);
  const { t } = useTranslation();

  return (
    <div className="terminal-block" key={itemKey}>
      <h3 className="terminal-like">
        {t(`PORTFOLIO.${groupKey}.VALUES.${itemKey}.TITLE`)}
      </h3>
      <p>
        {sectionData[itemKey].TECH.map((tech) => {
          return t(`TECHS.${tech}`);
        }).join(", ")}
      </p>
      <ul>
        {Object.keys(sectionData[itemKey].DESC_LIST).map((descKey) => {
          return (
            <li key={descKey}>
              <p className="bracketed">
                {t(
                  `PORTFOLIO.${groupKey}.VALUES.${itemKey}.DESC_LIST.${descKey}`
                )}
              </p>
            </li>
          );
        })}
        <li>
          <a
            href={`${sectionData[itemKey].GITHUB_URL}`}
            target="_blank"
            rel="noreferrer"
            className="bracketed"
          >
            {t("SEE_GITHUB")}
          </a>
        </li>
        {/* In case there's a live URL */}
        {sectionData[itemKey].LIVE_URL && (
          <li>
            <a
              href={`https://${sectionData[itemKey].LIVE_URL}`}
              target="_blank"
              rel="noreferrer"
              className="bracketed"
            >
              {
                <Trans i18nKey="SEE_LIVE">
                  {
                    t(`PORTFOLIO.${groupKey}.VALUES.${itemKey}.TITLE`).split(
                      "- "
                    )[0]
                  }
                </Trans>
              }
            </a>
          </li>
        )}
        {/* In case there's a video URL */}
        {sectionData[itemKey].VIDEO_URL && (
          <li>
            <button className="bracketed" onClick={modalCtx.openModal}>
              <Trans i18nKey="OPEN_VIDEO">
                {
                  t(`PORTFOLIO.${groupKey}.VALUES.${itemKey}.TITLE`).split(
                    "- "
                  )[0]
                }
              </Trans>
            </button>
          </li>
        )}
        {sectionData[itemKey].VIDEO_URL && (
          <ModalWrapper videoUrl={sectionData[itemKey].VIDEO_URL} />
        )}
      </ul>
    </div>
  );
};

export default PortfolioSection;
