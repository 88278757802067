import { useEffect } from "react";
import GitHubLogoLottie from "../../assets/lotties/github.json";
import LinkedInLogoLottie from "../../assets/lotties/linkedin.json";
import lottie from "lottie-web/build/player/lottie_light";
import "./_SocialBadges.scss";

const Social = () => {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#github-logo"),
      animationData: GitHubLogoLottie,
      loop: true,
      autoplay: true,
    });
    setTimeout(() => {
      lottie.loadAnimation({
        container: document.querySelector("#linkedin-logo"),
        animationData: LinkedInLogoLottie,
        autoplay: true,
        loop: false,
      });
    }, 1500);
  }, []);
  return (
    <div className="badges-container">
      <a
        href={"https://github.com/nicolasgasco"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span id="github-logo" className="logo"></span>
      </a>
      <a
        href={"https://www.linkedin.com/in/nicolasgasco/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span id="linkedin-logo" className="logo"></span>
      </a>
    </div>
  );
};

export default Social;
