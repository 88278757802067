import PersonalInfoSection from "./PersonalInfoSection/PersonalInfoSection";
import ProfessionalInfoContainer from "./ProfessionalInfoContainer/ProfessionalInfoContainer";
import en from "../../i18n/locales/en/en.json";

import "./_MainContent.scss";

const MainContent = () => {
  const professionalSectionItems = Object.keys(en["PROFESSIONAL_SECTION"]).map(
    (sectionKey, i) => {
      return (
        <ProfessionalInfoContainer
          key={sectionKey}
          sectionKey={sectionKey}
          sectionData={en["PROFESSIONAL_SECTION"][sectionKey].VALUES}
          groupKey={"PROFESSIONAL_SECTION"}
        />
      );
    }
  );

  const portfolioSection = Object.keys(en["PORTFOLIO"]).map((sectionKey) => {
    return (
      <ProfessionalInfoContainer
        key={sectionKey}
        sectionKey={sectionKey}
        sectionData={en["PORTFOLIO"][sectionKey].VALUES}
        groupKey={"PORTFOLIO"}
      />
    );
  });

  return (
    <>
      <PersonalInfoSection groupKey={"skills"} data={en} />
      <PersonalInfoSection groupKey={"about"} data={en} />
      {professionalSectionItems}
      {portfolioSection}
    </>
  );
};

export default MainContent;
