import "./_ShowMoreButton.scss";
import ArrowDown from "../../assets/svg/arrow_down.svg";
import { useTranslation } from "react-i18next";

const ShowMoreButton = ({ isMore }) => {
  const { t } = useTranslation();

  return (
    <img
      role="button"
      id="show-more-button"
      className={`${
        isMore ? "closed" : "open"
      } animate__animated animate__rotateIn animate__delay-1s`}
      src={ArrowDown}
      alt={isMore ? t("SHOW_MORE_BUTTON.MORE") : t("SHOW_MORE_BUTTON.MORE")}
    />
  );
};

export default ShowMoreButton;
