import { useTranslation } from "react-i18next";
import "./_WorkSection.scss";

const WorkSection = ({ sectionData, groupKey, itemKey }) => {
  const { t } = useTranslation();

  return (
    <div className="terminal-block" key={itemKey}>
      <h3 className="terminal-like">
        {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.EMPLOYER`)}
      </h3>
      <p className="inline-block">
        {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.JOB`)}
        <span className="piped">
          {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.PERIOD`)}
        </span>
      </p>
      <ul>
        {Object.keys(sectionData[itemKey].DESC_LIST).map((descItem) => {
          return (
            <li key={`work-${descItem}`}>
              <p className="bracketed">
                {t(
                  `PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.DESC_LIST.${descItem}`
                )}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default WorkSection;
