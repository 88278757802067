import { useTranslation } from "react-i18next";
import "./EducationSection";

const EducationSection = ({ sectionData, groupKey, itemKey }) => {
  const { t } = useTranslation();

  return (
    <div className="terminal-block">
      <h3 className="html-like">
        {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.SCHOOL`)}
      </h3>
      <p className="inline-block">
        {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.SUBJECT`)}
        <span className="piped">
          {t(`PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.PERIOD`)}
        </span>
      </p>
      <ul>
        {sectionData[itemKey].DESC_LIST &&
          Object.keys(sectionData[itemKey].DESC_LIST).map((descItem) => {
            return (
              <li key={`education-desc-${descItem}`}>
                <p className="bracketed">
                  {t(
                    `PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.DESC_LIST.${descItem}`
                  )}
                </p>
              </li>
            );
          })}

        {sectionData[itemKey].URL_DESC &&
          Object.keys(sectionData[itemKey].URL_DESC).map((descItem) => {
            return (
              <li key={`education-url-${descItem}`}>
                <p className="bracketed">
                  {t(
                    `PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.URL_DESC.${descItem}.TEXT`
                  )}
                  <a
                    href={`https://${sectionData[itemKey].URL_DESC[descItem].URL}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(
                      `PROFESSIONAL_SECTION.${groupKey}.VALUES.${itemKey}.URL_DESC.${descItem}.URL`
                    )}
                  </a>
                </p>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default EducationSection;
