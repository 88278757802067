import React, { useState } from "react";

export const ModalContext = React.createContext({
  isOpen: false,
  toggleModal: () => {},
  closeModal: () => {},
  openModal: () => {},
});

export const ModalProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen((prevState) => {
      return !prevState;
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const modalContext = {
    isOpen: isOpen,
    toggleModal: toggleModal,
    closeModal: closeModal,
    openModal: openModal,
  };

  return (
    <ModalContext.Provider value={modalContext}>
      {props.children}
    </ModalContext.Provider>
  );
};

