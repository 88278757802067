import HeaderSection from "../components/Header/HeaderSection";
import MainContent from "../components/Main/MainContent";

const HomePage = () => {
  return (
    <>
      <HeaderSection />
      <MainContent />
    </>
  );
};

export default HomePage;
