import { useTranslation } from "react-i18next";
import Portrait from "../../assets/img/gasco.webp";
import SocialBadges from "./SocialBadges";

const HeaderSection = () => {
  const { t } = useTranslation();

  return (
    <header className="header-container animate__animated animate__fadeInDown">
      <div className="picture-container">
        <img src={Portrait} alt="" className="portrait" />
      </div>
      <div>
        <div className="title-subtitle-container">
          <h1>Nicolas Gasco</h1>
          <p
            dangerouslySetInnerHTML={{ __html: t("HEADER.PERSONAL_INTRO") }}
          ></p>
        </div>
        <SocialBadges />
      </div>
    </header>
  );
};

export default HeaderSection;
